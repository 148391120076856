<template>
  <div>
    <!-- <b-input-group
      :prepend="limit_type == 1 ? 'Horas presenciales' : 'Créditos máximo'"
      class="mt-3"
    > -->
    <b-form-input
      type="number"
      min="0"
      v-model="limit_study_plan.restriction_value"
    ></b-form-input>
    <!-- </b-input-group> -->
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "LimitStudyPlanInputComponent",
  props: {
    LimitStudyPlan: {
      type: Object,
      default: null,
    },
    modality_egress_profile_id: {
      type: Number,
      required: true,
    },
    sub_type: {
      type: Number,
      required: true,
    },
    sub_type_id: {
      default: null,
    },
    limit_type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      limit_study_plan: {
        id: this.LimitStudyPlan ? this.LimitStudyPlan.id : generateUniqueId(),
        modality_egress_profile: this.LimitStudyPlan
          ? this.LimitStudyPlan.modality_egress_profile
          : this.modality_egress_profile_id,
        sub_type: this.LimitStudyPlan
          ? this.LimitStudyPlan.sub_type
          : this.sub_type,
        sub_type_id: this.LimitStudyPlan
          ? this.LimitStudyPlan.sub_type_id
          : this.sub_type_id,
        limit_type: this.LimitStudyPlan
          ? this.LimitStudyPlan.limit_type
          : this.limit_type,
        restriction_value: this.LimitStudyPlan
          ? this.LimitStudyPlan.restriction_value
          : 0,
      },
    };
  },
  computed: {},
  methods: {
    changeInput() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (isNaN(this.limit_study_plan.id)) this.createdLimitStudyPlan();
        else this.updateLimitStudyPlan();
      }, 1000);
    },
    createdLimitStudyPlan() {
      this.$restful
        .Post(`/mesh/limit-study-plan/`, this.limit_study_plan)
        .then((response) => {
          toast(
            String(
              this.limit_study_plan.limit_type == 1
                ? "Hora actualizada."
                : "Créditos actualizado."
            )
          );
          this.$emit("created", response);
          this.limit_study_plan = response;
        });
    },
    updateLimitStudyPlan() {
      this.$restful
        .Put(
          `/mesh/limit-study-plan/${this.limit_study_plan.id}/`,
          this.limit_study_plan
        )
        .then((response) => {
          toast(
            String(
              this.limit_study_plan.limit_type == 1
                ? "Hora actualizada."
                : "Créditos actualizado."
            )
          );
          this.$emit("updated", this.limit_study_plan);
          this.limit_study_plan = response;
        });
    },
  },
  watch: {
    "limit_study_plan.restriction_value": function (n, o) {
      if (n == o) return;
      if (n < 0 || n == "") return;
      this.changeInput();
    },
  },
};
</script>

<style scoped>
</style>